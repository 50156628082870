import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import companyPic from './../../images/company2.jpg'

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={5}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='公司简介' />
        <div className='core-tech-wrap company-wrap'>
          <div className='main-img'></div>
          <div
            className='trace-source'
            style={{ maxWidth: '1920px', margin: '0px auto' }}
          >
            <img
              src={companyPic}
              alt='公司简介'
              style={{ display: 'block', width: '100%' }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
